/* eslint-disable */
import axios from 'axios';
import { API_URL } from '@/common/config';
import Swal from 'sweetalert2';

export function showSimpleMessage(title, text, icon = 'info', timer = 1500) {
  Swal.fire({
    icon,
    title,
    text,
    showConfirmButton: false,
    timer,
    timerProgressBar: 'my-progress-bar',
  });
}
export function showConfirmacionMessage(
  title = '¿Desea confirmar?',
  text = '',
  icon = 'warning',
  showCancelButton = true
) {
  // icon="info", 'warning', 'success', 'danger'
  return Swal.fire({
    title,
    text,
    icon,
    showCancelButton,
    confirmButtonText: 'Si',
    cancelButtonText: 'No, Cancelar',
    showCloseButton: true,
    showLoaderOnConfirm: true,
  }).then(async (result) => {
    if (result.isConfirmed) {
      return true;
    }
    return false;
  });
}
export function formatDate(d) {
  const date = new Date(d);
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return (d = dd + '/' + mm + '/' + yyyy);
}
export async function getCodJerarquia(nombreOperatoria) {
  try {
    if (!nombreOperatoria) return;
    const url = `${API_URL}api/trilay/codjerarquia?nombreOperatoria=${nombreOperatoria}`;
    const header = {
      headers: {
        token: localStorage.getItem('token'),
      },
    };

    return await axios
      .get(url, header)
      .then((r) => {
        if (r.data.status === 'success') {
          return r.data.codJerarquia;
        }
        return false;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  } catch (e) {
    console.error(e);
  }
}
function getEstadoTrilayId(estado_id) {
  let res;
  switch (estado_id) {
    case 7:
      res = 1;
      break;
    default:
      res = 1;
      break;
  }
  return res;
}
function getFechaInicio(cm) {
  return Object.keys(cm)[0].replace(/-/g, '/');
}
function getFechaUltimaCuota(cm) {
  const last = Object.keys(cm).length - 1;
  return Object.keys(cm)[last].replace(/-/g, '/');
}
function getTotales(cm, campo) {
  const sum = Object.values(cm).reduce((accumulator, object) => {
    return accumulator + Number.parseFloat(object[campo]);
  }, 0);
  return sum;
}

function roundArray(cuadro) {
  if (cuadro) {
    //hago un redondeo de los valores del cuadro marcha
    const formattedData = Object.fromEntries(
      Object.entries(cuadro).map(([key, value]) => {
        const formattedValues = {
          ...value,
          Saldo_Inicial: +value.Saldo_Inicial.toFixed(2),
          Amortizacion: +value.Amortizacion.toFixed(2),
          Intereses: +value.Intereses.toFixed(2),
          Saldo_Final: +value.Saldo_Final.toFixed(2),
          IVA_Intereses: +value.IVA_Intereses.toFixed(2),
          Cuota: +value.Cuota.toFixed(2),
          Cuota_Total: +value.Cuota_Total.toFixed(2),
        };
        return [key, formattedValues];
      })
    );
    return formattedData;
  }
}
export async function convertToModuloCredito(items) {
  try {
    const item = items;
    if (!item.cliente.cuit) {
      console.log('error', 'El CUIT/CUIL es obligatorio.');
      return {
        error: true,
        title: 'Error de validación',
        message: 'El CUIT/CUIL es obligatorio.',
      };
    }
    const codjerarquia = await getCodJerarquia(item.tipo_operatoria);
    const gast_admin_iibb =
      Number.parseFloat(item.gasto_admin) + Number.parseFloat(item.iibb);
    const gastos_otorga = item.gasto_otorga.toFixed(2);

    const {
      nombre,
      apellido,
      domicilioReal: domicilio,
      cuit: nrodoc,
      celular: tel,
      mail: mail,
    } = item.cliente;

    return {
      cliente: {
        razonsocial: `${nombre} ${apellido}`,
        nrodoc,
        tel,
        mail,
        codjerarquia,
        fecha: new Date().toLocaleDateString('en-GB').toString(),
        domicilio,
      },
      codjerarquia,
      coddestino: 2,
      codcliente: item.lineacreditocliente[0].codigo_cliente_trilay,
      fecha_solicitud: formatDate(item.lineacreditocliente[0].createdAt),
      fecha_inicio: getFechaInicio(item.cuadro),
      fecha_ultima_cuota: getFechaUltimaCuota(item.cuadro),
      fecha_desembolso: item.lineacreditocliente[0].fecha_desembolso,
      cant: 1, // ciclo
      id: item.lineacreditocliente[0].id,
      estado_id: getEstadoTrilayId(item.lineacreditocliente[0].estado_id),
      fecha_nac: '',
      edad_solic: 0,
      operatoria: item.tipo_operatoria,
      nrodoc: item.cliente.cuit,
      monto_total: item.liquidacion.total,
      coeficiente_int_moratorio: 0.11,
      monto_desembolso: Math.floor(Number(item.params.monto)),
      frecuencia: item.params.periodicidad_pagos,
      tipo_amortizacion: 1,
      plazo: item.params.plazo_credito / item.params.periodicidad_pagos,
      taza: item.tasa,
      perdiodo_gracia: item.params.periodo_gracia,
      gastos: `2,0,${gast_admin_iibb.toFixed(2)},0.00,${gast_admin_iibb.toFixed(
        2
      )},0.00|1,0,${gastos_otorga},2.00,${gastos_otorga},0.00`,
      cuadro_marcha: item.cuadro,
      monto_total_intereses: Math.floor(
        Number(getTotales(item.cuadro, 'Intereses'))
      ),

      monto_capital_devolver: Math.floor(
        Number(getTotales(item.cuadro, 'Cuota_Total'))
      ),
    };
  } catch (e) {
    console.log(e);
    return 'error';
  }
}
